import { Auth } from "@aws-amplify/auth";
import {
    INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR,
    INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR, STATUS_ID,
    FILES_CATEGORIES,
    REGEX,
    POLICY_ID,
    MONTHS_LIST
} from "./constans";
import UtilsConstants from '../constants/utils-constants';
import { useCallback } from "react";

const constants = UtilsConstants

const orderListrequest = (listRequest, listOrder) => {

    let objectKeys = Object.keys(listRequest[constants.NUMBERS.ZERO]);
    let listObject = [];

    if (listOrder.length === objectKeys.length) {

        for (let j = constants.NUMBERS.ZERO; j < listRequest.length; j++) {

            let itemList = {}

            for (let i = constants.NUMBERS.ZERO; i < objectKeys.length; i++) {
                itemList[listOrder[i]] = listRequest[j][listOrder[i]];
            }

            listObject.push(itemList);
        }
        return listObject;
    }
    return constants.NUMBERS.ZERO;
}

const removePropertyByKey = (object, deleteKey) => {
    return Object.keys(object)
        .filter(key => key !== deleteKey)
        .reduce((result, current) => {
            result[current] = object[current];
            return result;
        }, {});
}

const parseCurrencyToVal = (currencyId, event, callbackFunction) => {
    let value = event.target.value.toString();
    const DECIMAL_SEPARATOR = currencyId === constants.NUMBERS.ONE ? constants.SIGNS.COMMA : constants.SIGNS.POINT;
    const DECIMAL_REGEX = currencyId === constants.NUMBERS.ONE ? constants.REGEX.DIGITS_AND_POINTS : constants.REGEX.DIGITS_AND_COMMAS;
    const THOUSAND_REGEX = currencyId === constants.NUMBERS.ONE ? constants.REGEX.DIGITS_AND_COMMAS : constants.REGEX.DIGITS_AND_POINTS;

    if ((value.match(DECIMAL_REGEX) || []).length > constants.NUMBERS.ONE) return;

    const MAX_VALUE_LENGTH = value.includes(DECIMAL_SEPARATOR) ?
        INPUT_MAX_LENGTH_WITH_DECIMAL_SEPARATOR - constants.NUMBERS.ONE : INPUT_MAX_LENGTH_WITHOUT_DECIMAL_SEPARATOR - constants.NUMBERS.ONE;

    if (value.length < MAX_VALUE_LENGTH) {
        value = value.replace(THOUSAND_REGEX, constants.EMPTY_STRING);
        if (DECIMAL_SEPARATOR === constants.SIGNS.COMMA) value = value.replace(DECIMAL_REGEX, constants.SIGNS.POINT);
        if ((value.split(constants.SIGNS.POINT)[constants.NUMBERS.ONE] || constants.EMPTY_STRING).length <= constants.NUMBERS.TWO) callbackFunction(event, value);
    } else {
        alert(MAX_VALUE_LENGTH === constants.LENGTH.TWENTY_ONE ? constants.ALERT.MESSAGES.NOT_MORE_DIGIT_TITLE : constants.ALERT.MESSAGES.NOT_MORE_DIGIT_TEXT )
    }

    

}

const formatValToCurrency = (currencyId, currentValue, provideDefaultDecimals = constants.FALSE) => {
    const LOCAL_CODE = currencyId === constants.NUMBERS.ONE ? constants.LOCAL_CODE.DE : constants.LOCAL_CODE.EN
    const DECIMAL_SEPARATOR = currencyId === constants.NUMBERS.ONE ? constants.SIGNS.COMMA : constants.SIGNS.POINT

    let result;

    if (currentValue !== constants.EMPTY_STRING) {
        if (currentValue.toString().includes(constants.SIGNS.POINT)) {
            let resultArray = currentValue.toString().split(constants.SIGNS.POINT)
            if (provideDefaultDecimals) resultArray[constants.NUMBERS.ONE] = (resultArray[constants.NUMBERS.ONE]).padEnd(constants.NUMBERS.TWO, constants.NUMBERS_STRING.ZERO);
            result = Intl.NumberFormat(LOCAL_CODE).format(resultArray[constants.NUMBERS.ZERO]) + DECIMAL_SEPARATOR + resultArray[constants.NUMBERS.ONE];
        } else {
            result = Intl.NumberFormat(LOCAL_CODE).format(currentValue);
            if (provideDefaultDecimals) result = Intl.NumberFormat(LOCAL_CODE).format(currentValue) + DECIMAL_SEPARATOR + constants.NUMBERS_STRING.DOUBLE_ZERO;
        }
    }

    return result;
}

const validateInputDecimals = (valueToCheck) => {
    let finalValue;
    if (valueToCheck) {
        if (valueToCheck.slice(- constants.NUMBERS.ONE) === constants.SIGNS.POINT || valueToCheck.slice(- constants.NUMBERS.TWO) === constants.NUMBERS_STRING.POINT_ZERO || valueToCheck.slice(- constants.NUMBERS.THREE) === constants.NUMBERS_STRING.POINT_DOUBLE_ZERO) {
            finalValue = valueToCheck;
        } else {
            finalValue = parseFloat(valueToCheck);
        }
    } else {
        finalValue = constants.NUMBERS.ZERO;
    }
    return finalValue;
}

const getAccessJwtToken = async () => {
    const session = await Auth.currentSession();
    const jwt = session.getIdToken().getJwtToken();

    return jwt;
};

const getAxiosHeaders = async () => ({
    Authorization: await getAccessJwtToken(),
})

const currencyAllowedKeyCodes = constants.CURRENCY_ALLOWED_KEY_CODES;

const numericAllowedKeyCodes = constants.NUMERIC_ALLOWED_KEY_CODES;

const phoneAllowedKeyCodes = constants.PHONE_ALLOWED_KEY_CODES;

const avoidNonNumericValues = (event) => {
    if (!currencyAllowedKeyCodes.includes(event.which)) event.preventDefault();
}

const isNumberKey = (event) => {
    if (!numericAllowedKeyCodes.includes(event.which)) event.preventDefault();
};

const isPhoneKey = (event) => {
    if (!phoneAllowedKeyCodes.includes(event.which)) event.preventDefault();
};

const getValueDropdown = (key, value, list) => { 
    const dropdown = list.find(dropdown => dropdown[key] === value);
    return dropdown ? dropdown.name || dropdown.nombre : undefined;
};

const isInvoiceRequestDisable = (data) => {
    if (data.facSolicitudId && (data.estadoId !== STATUS_ID.DEVUELTA && data.estadoId !== STATUS_ID.GUARDADA)) return constants.TRUE;
    if (data.polSolicitudId) return constants.TRUE;
    return constants.FALSE;
}

const validateFieldConditionLength = (fieldLength) => {
    if (fieldLength <= constants.NUMBERS.ONE) {
        return { aspect: constants.ALERT.TYPE.ERROR, help: constants.ALERT.MESSAGES.LENGTH };
    } else {
        return undefined;
    }
};

const validateFieldConditionNotOnlyNumbers = (fieldValue) => {
    if (constants.REGEX.ONLY_NUMBERS.test(fieldValue)) {
        return { aspect: constants.ALERT.TYPE.ERROR, help: constants.ALERT.MESSAGES.NOT_ONLY_NUMBERS };
    } else {
        return undefined;
    }
};

const validateFieldConditionOnlyNumbers = (fieldValue) => {
    if (!constants.REGEX.ONLY_NUMBERS.test(fieldValue)) {
        return { aspect: constants.ALERT.TYPE.ERROR, help: constants.ALERT.MESSAGES.ONLY_NUMBERS };
    } else {
        return undefined;
    }
};

const validateFieldConditionOnlyLetters = (fieldValue) => {
    if (!constants.REGEX.ONLY_LETTERS.test(fieldValue)) {
        return { aspect: constants.ALERT.TYPE.ERROR, help: constants.ALERT.MESSAGES.NOT_NUMBERS};
    } else {
        return undefined;
    }
};

const validateFieldConditionNotSpecialCharacters = (fieldValue) => {
    if (constants.REGEX.SPECIAL_CHARACTERS.test(fieldValue)) {
        return { aspect: constants.ALERT.TYPE.ERROR, help: constants.ALERT.MESSAGES.NO_SPECIAL_CHARACTERS };
    } else {
        return undefined;
    }
};

const validateField = (fieldName, fieldValue) => {
    const errors = {};

    const fieldLength = fieldValue.length;

    const fieldErrorLength = validateFieldConditionLength(fieldLength);
    const fieldErrorNotOnlyNumbers = validateFieldConditionNotOnlyNumbers(fieldValue);
    const fieldErrorOnlyNumbers = validateFieldConditionOnlyNumbers(fieldValue);
    const fieldErrorNotSpecialCharacters = validateFieldConditionNotSpecialCharacters(fieldValue);
    const fieldErrorOnlyLetters = validateFieldConditionOnlyLetters(fieldValue);
    
    switch (fieldName) {
        case constants.VALIDATE_FIELD.NAME:
            errors[fieldName] = fieldErrorLength || fieldErrorNotOnlyNumbers || fieldErrorNotSpecialCharacters;
            break;

        case constants.VALIDATE_FIELD.COSTCENTER:
            errors[fieldName] = fieldErrorLength || fieldErrorNotSpecialCharacters;
            break;
        
        case constants.VALIDATE_FIELD.SYSTEM_CODE:
            errors[fieldName] = fieldErrorLength || fieldErrorNotSpecialCharacters;
            break;
        
        case constants.VALIDATE_FIELD.CITY:
            errors[fieldName] = fieldErrorLength || fieldErrorNotOnlyNumbers;
            break;
        
        case constants.VALIDATE_FIELD.STATE:
            errors[fieldName] = fieldErrorLength || fieldErrorNotOnlyNumbers || fieldErrorNotSpecialCharacters;
            break;

        case constants.VALIDATE_FIELD.SORTNAME:
            errors[fieldName] = fieldErrorLength || fieldErrorOnlyLetters || fieldErrorNotSpecialCharacters;
            break;

        case constants.VALIDATE_FIELD.COUNTRY:
            errors[fieldName] = fieldErrorLength || fieldErrorOnlyLetters || fieldErrorNotSpecialCharacters;
            break;

        case constants.VALIDATE_FIELD.PHONE_CODE:
            errors[fieldName] = fieldErrorLength || fieldErrorNotSpecialCharacters;
            break;
        
        case constants.VALIDATE_FIELD.RAZON_SOCIAL_RUT:
            errors[fieldName] = fieldErrorLength;
            break;

        case constants.VALIDATE_FIELD.NUMERO_DOCUMENTO:
            errors[fieldName] = fieldErrorLength || fieldErrorNotSpecialCharacters;
            break;

        case constants.VALIDATE_FIELD.DIRECCION_RUT:
            errors[fieldName] = fieldErrorLength || fieldErrorNotOnlyNumbers;
            break;

        case constants.VALIDATE_FIELD.CODIGO_POSTAL:
            errors[fieldName] = fieldErrorLength || fieldErrorNotSpecialCharacters;
            break;

        case constants.VALIDATE_FIELD.TELEFONO_RUT:
            errors[fieldName] = fieldErrorLength || fieldErrorNotSpecialCharacters;
            break;

        case constants.VALIDATE_FIELD.CELULAR_RUT: 
            errors[fieldName] = fieldErrorLength || fieldErrorNotSpecialCharacters;
            break

        case constants.VALIDATE_FIELD.EMAIL_FACTURACION:
            errors[fieldName] = fieldErrorLength || fieldErrorNotOnlyNumbers;
            break;
            
        case constants.VALIDATE_FIELD.PLAZO_PAGO:
            errors[fieldName] = fieldErrorNotSpecialCharacters || fieldErrorOnlyNumbers;
            break

        case constants.VALIDATE_FIELD.IVA:
            errors[fieldName] = fieldErrorNotSpecialCharacters;
            break

        case constants.VALIDATE_FIELD.RETEIVA:
            errors[fieldName] = fieldErrorNotSpecialCharacters;
            break

        case constants.VALIDATE_FIELD.RETEFUENTE:  
            errors[fieldName] = fieldErrorNotSpecialCharacters;
            break
        
        case constants.VALIDATE_FIELD.RETEICA:
            errors[fieldName] =  fieldErrorNotSpecialCharacters;
            break
            
        case constants.VALIDATE_FIELD.TAX_REGIME:
            errors[fieldName] =  fieldErrorNotSpecialCharacters ||fieldErrorOnlyNumbers;
            break;

        case constants.VALIDATE_FIELD.DIRECTION:
            errors[fieldName] = fieldErrorLength || fieldErrorNotOnlyNumbers;
            break;

        case constants.VALIDATE_FIELD.TELEPHONE:
            errors[fieldName] = fieldErrorLength || fieldErrorOnlyNumbers;
            break;
        
        case constants.VALIDATE_FIELD.CONTACT:
            errors[fieldName] = fieldErrorLength || fieldErrorNotOnlyNumbers;
            break

        case constants.VALIDATE_FIELD.CONTACT_CHARGE:
            errors[fieldName] = fieldErrorLength || fieldErrorOnlyLetters || fieldErrorNotSpecialCharacters;
            break;

        case constants.VALIDATE_FIELD.BUY_ORDER:
            errors[fieldName] = fieldErrorLength;
            break;

        case constants.VALIDATE_FIELD.CONCEPT_VALUE:
            errors[fieldName] = fieldErrorLength || fieldErrorNotSpecialCharacters || fieldErrorOnlyNumbers;
            break;
        
        default:
            break;
    }

    return errors;
};

const validateBrandField = (brandData, callBack) => {
    if (brandData.name === constants.EMPTY_STRING)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.BRAND.NAME_BRAND });

    if (brandData.costCenter === constants.EMPTY_STRING)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.BRAND.COSTCENTER_BRAND });

    if (brandData.clients.length === constants.NUMBERS.ZERO)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.BRAND.CLIENT_BRAND });

    if (brandData.filiales.length === constants.NUMBERS.ZERO)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.BRAND.FILIAL_BRAND });

    callBack();
}

const validateInvoiceField = (dataFactura, callBack) => {

    if (dataFactura.fechaLimiteEntrega === constants.EMPTY_STRING || !dataFactura.fechaLimiteEntrega.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.REQUIRED_LIMIT_DATE, trim: constants.ALERT.TRIM.LIMIT_DATE });

    if (dataFactura.clienteId === constants.NUMBERS.MINUS_ONE)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.SELECTED_CLIENT });

    if (dataFactura.filialId === constants.NUMBERS.ZERO)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.SELECTED_FILIAL });

    if (dataFactura.contacto === constants.EMPTY_STRING || !dataFactura.contacto.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.REQUIRED_CONTACT, trim: constants.ALERT.TRIM.CONTACT });

    if (dataFactura.cargoContacto === constants.EMPTY_STRING || !dataFactura.cargoContacto.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.REQUIRED_CHARGE, trim: constants.ALERT.TRIM.CONTACT_CHARGE });

    if (dataFactura.plazoPago === constants.NUMBERS.MINUS_ONE)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.REQUIRED_PAYMENT_CONDITIONS });

    if (dataFactura.plazoPago === constants.EMPTY_SPACE_STRING)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.VALUE_DAYS_PAYMENT });

    if (dataFactura.conceptos.length <= constants.NUMBERS.ZERO)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_INFORMATION, details: constants.ALERT.DETAILS.INVOICE.REQUIRED_CONCEPTS });


    dataFactura.conceptos.forEach((concepto, i) => {

        if (concepto.descripcion === constants.UNDEFINED || concepto.descripcion === constants.EMPTY_STRING || !concepto.descripcion.trim())
            throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.REQUIRED_CONCEPT_DESCRIPTION + (i + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.REQUIRED })

        concepto.centroCostoDTO.forEach((centroCosto, j) => {
            if (centroCosto.negocioId === constants.NUMBERS.ZERO || !centroCosto.negocioId)
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.COSTCENTER_BUSSINES + (j + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.CONCEPT_NUMBER + (i + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.REQUIRED });

            if (centroCosto.marcaId === constants.NUMBERS.ZERO || !centroCosto.marcaId)
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.COSTCENTER_BRAND + (j + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.CONCEPT_NUMBER + (i + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.REQUIRED });

            if (centroCosto.valor === constants.NUMBERS.ZERO)
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.COSTCENTER_VALUE + (j + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.CONCEPT_NUMBER + (i + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.REQUIRED });

            if (centroCosto.tipoIngresoId === constants.NUMBERS.ZERO || !centroCosto.tipoIngresoId)
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.INVOICE.COSTCENTER_INGRESS_TYPE + (j + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.CONCEPT_NUMBER + (i + constants.NUMBERS.ONE) + constants.ALERT.DETAILS.INVOICE.REQUIRED });
        });
    });
    callBack();
}

const validateClientFields = (dataClient, permissionsFilial, callBack) => {
    if (dataClient.razonSocialRut === constants.EMPTY_STRING || !dataClient.razonSocialRut.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_NAME });

    if (dataClient.numeroDocumento === constants.EMPTY_STRING || !dataClient.numeroDocumento.toString().trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_NIT });

    if (!REGEX.NUMBER_REGEX.test(dataClient.numeroDocumento.toString()))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.NIT_ONLY_NUMBERS });

    if (dataClient.paisId === constants.EMPTY_STRING || !dataClient.paisId.toString().trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_COUNTRY });

    if (dataClient.departamentoId === constants.EMPTY_STRING || !dataClient.departamentoId.toString().trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_STATE });

    if (dataClient.direccionRut === constants.EMPTY_STRING || !dataClient.direccionRut.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_ADDRESS });

    if (dataClient.ciudadId === constants.EMPTY_STRING || !dataClient.ciudadId.toString().trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_CITY });

    if (!permissionsFilial && (dataClient.codigoPostal === constants.EMPTY_STRING || !dataClient.codigoPostal.toString().trim()))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_POSTAL_CODE });

    if (!permissionsFilial && !REGEX.NUMBER_REGEX.test(dataClient.codigoPostal))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_FORMAT, details: constants.ALERT.DETAILS.CLIENTS.POSTAL_CODE_ONLY_NUMBERS });

    if (dataClient.telefonoRut === constants.EMPTY_STRING || !dataClient.telefonoRut.toString().trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_PHONE });

    if (!REGEX.PHONE_REGEX.test(dataClient.telefonoRut))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_FORMAT, details: constants.ALERT.DETAILS.CLIENTS.PHONE_FORMAT });

    if (dataClient.celularRut === constants.EMPTY_STRING || !dataClient.celularRut.toString().trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_CELLPHONE });

    if (!REGEX.PHONE_REGEX.test(dataClient.celularRut))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_FORMAT, details: constants.ALERT.DETAILS.CLIENTS.CELLPHONE_FORMAT });

    if (dataClient.emailFacturacion === constants.EMPTY_STRING || !dataClient.emailFacturacion.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_EMAIL });

    if (!REGEX.MAIL_REGEX.test(dataClient.emailFacturacion))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_FORMAT, details: constants.ALERT.DETAILS.CLIENTS.EMAIL_FORMAT });

    if (dataClient.plazoPago === constants.EMPTY_STRING || !dataClient.plazoPago.toString().trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.CLIENTS.REQUIRED_PAYMENT_TERM });

    if (!REGEX.NUMBER_REGEX.test(dataClient.plazoPago.toString()))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_FORMAT, details: constants.ALERT.DETAILS.CLIENTS.PAYMENT_TERM_ONLY_NUMBERS });

    if (!dataClient.files.find((file) => file.categoria === FILES_CATEGORIES.CAMARA_COMERCIO))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FILE, details: constants.ALERT.DETAILS.CLIENTS.SET_COMERCIAL_DOCUMENT });

    if (!dataClient.files.find((file) => file.categoria === FILES_CATEGORIES.RUT))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FILE, details: constants.ALERT.DETAILS.CLIENTS.SET_FISCAL_DOCUMENT });

    if (dataClient.iva !== constants.EMPTY_STRING && (parseInt(dataClient.iva) < constants.NUMBERS.ZERO || parseInt(dataClient.iva) > constants.NUMBERS.FIFTY))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_VALUE, details: constants.ALERT.DETAILS.CLIENTS.IVA_BETWEEN_0_50 });

    if (dataClient.reteIva !== constants.EMPTY_STRING && (parseInt(dataClient.reteIva) < constants.NUMBERS.ZERO || parseInt(dataClient.reteIva) > constants.NUMBERS.FIFTY))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_VALUE, details: constants.ALERT.DETAILS.CLIENTS.RETEIVA_BETWEEN_0_50 });

    if (dataClient.reteFuente !== constants.EMPTY_STRING && (parseInt(dataClient.reteFuente) < constants.NUMBERS.ZERO || parseInt(dataClient.reteFuente) > constants.NUMBERS.FIFTY))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_VALUE, details: constants.ALERT.DETAILS.CLIENTS.RETEFUENTE_BETWEEN_0_50 });

    if (dataClient.reteIca !== constants.EMPTY_STRING && (parseInt(dataClient.reteIca) < constants.NUMBERS.ZERO || parseInt(dataClient.reteIca) > constants.NUMBERS.FIFTY))
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_VALUE, details: constants.ALERT.DETAILS.CLIENTS.ICA_BETWEEN_0_50 });

    callBack();
};

const validatePolicyFields = (policyData, callBack) => {
    if (policyData.fechaLimiteEntrega === constants.EMPTY_STRING || !policyData.fechaLimiteEntrega.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.REQUIRED_LIMIT_DATE, trim: constants.ALERT.TRIM.LIMIT_DATE });

    if (policyData.clienteId === constants.NUMBERS.MINUS_ONE)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.REQUIRED_CLIENT });

    if (policyData.contacto === constants.EMPTY_STRING || !policyData.contacto.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.REQUIRED_CONTACT, trim: constants.ALERT.TRIM.CONTACT });

    if (policyData.cargoContacto === constants.EMPTY_STRING || !policyData.cargoContacto.trim())
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.REQUIRED_CHARGE, trim: constants.ALERT.TRIM.CONTACT_CHARGE });

    if (!policyData.polSolicitudId && policyData.tiposPolizasSolicitud.length === constants.NUMBERS.ZERO)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.REQUIRED_POLICY_TYPE, trim: constants.ALERT.TRIM.CONTACT_CHARGE });

    if (policyData.filialId == constants.EMPTY_STRING)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.REQUIRED_FILIAL });

    if (policyData.marcas.length === constants.NUMBERS.ZERO)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.REQUIRED_BRAND });

    if (policyData.negocios.length === constants.NUMBERS.ZERO)
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.REQUIRED_BUSSINES });


    const otherPolicy = policyData.tiposPolizasSolicitud.find(policy => policy.tipoPolizaId === POLICY_ID.OTRA)
    if (otherPolicy && !policyData.polSolicitudId && otherPolicy.descripcionOtra === constants.EMPTY_STRING) {
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.POLICY.OTHER_POLICY });
    }

    if (policyData.polSolicitudId) {
        policyData.tiposPolizasSolicitud.forEach(policyType => {
            if (policyType.files < constants.NUMBERS.ONE) {
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.ALERT, details: constants.ALERT.DETAILS.POLICY.REQUIRED_FILES });
            }
        })
    }

    callBack();
}

const validateLocationData = (type, data, callback) => {
    switch (type) {
        case constants.CASE.COUNTRY:
            if (data.sortname === constants.EMPTY_STRING || !data.sortname.trim())
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.LOCATION_DATA.REQUIRED_COD_COUNTRY });
            if (data.name === constants.EMPTY_STRING || !data.name.trim())
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.LOCATION_DATA.REQUIRED_NAME });
            if (data.phonecode === constants.EMPTY_STRING || !data.phonecode.toString().trim())
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.LOCATION_DATA.REQUIRED_COD_PHONE });
            if (data.phonecode.length > constants.NUMBERS.TEN )
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.NOT_MORE_DIGIT_TITLE, details: constants.ALERT.DETAILS.LOCATION_DATA.PHONE_LENGTH });
            if (data.sortname.length > constants.NUMBERS.THREE)
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.NOT_MORE_DIGIT_TITLE, details: constants.ALERT.DETAILS.LOCATION_DATA.COD_COUNTRY_LENGTH });
            if (!REGEX.PHONE_REGEX.test(data.phonecode))
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.INCORRECT_FORMAT, details: constants.ALERT.DETAILS.LOCATION_DATA.PHONE_FORMAT });
            break;
        case constants.CASE.STATE:
            if (!data.paisId)
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.LOCATION_DATA.REQUIRED_COUNTRY });
            if (data.estado === constants.EMPTY_STRING || !data.estado.trim())
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.LOCATION_DATA.REQUIRED_STATE });
            break;
        case constants.CASE.CITY:
            if (!data.paisId)
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.LOCATION_DATA.REQUIRED_COUNTRY });
            if (!data.estadoId)
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.LOCATION_DATA.SELECT_STATE });
            if (data.ciudad === constants.EMPTY_STRING || !data.ciudad.trim())
                throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.LOCATION_DATA.REQUIRED_CITY });
            break;
        default:
            break;
    }

    callback();
}

const validateUserFields = (userData, callBack) => {
    const ROL_ID_SOLICITANTE = constants.NUMBERS.FOUR;
    if (!userData.roleId) {
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.USER.REQUIRED_ROLE });
    }
    if ((ROL_ID_SOLICITANTE === userData.roleId) && !userData.codigoSistema) {
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.USER.REQUIRED_COD_SYSTEM });
    }
    if (userData.negocios.length === constants.NUMBERS.ZERO) {
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.USER.REQUIRED_BUSSINES });
    }
    if (userData.filiales.length === constants.NUMBERS.ZERO) {
        throw ({ type: constants.ALERT.TYPE.ERROR, msg: constants.ALERT.MESSAGES.REQUIRED_FIELD, details: constants.ALERT.DETAILS.USER.FILIAL_CONTAIN});
    }
    callBack();
}

const toBase64 = async (file, fileCategory, callBack) => {
    if (typeof file === constants.OBJECT) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const fileData = {
                categoria: fileCategory,
                nombreArchivo: file.name,
                file: reader.result.split(constants.SIGNS.COMMA)[constants.NUMBERS.ONE],
            };
            callBack(fileData);
        };
    }
}

const getStatusName = (statusId) => {
    let newObj = {}

    for (let [k, v] of Object.entries(STATUS_ID)) {
        if (v === statusId) {
            newObj[v] = k;
        }
    }
    return Object.values(newObj)[constants.NUMBERS.ZERO];
}

const formatDate = (stringDate, formatType) => {
    if (!stringDate) {
        return;
    }

    let splitDate = stringDate.split(constants.SIGNS.GUION);
    let facDay = splitDate[constants.NUMBERS.TWO].substring(constants.NUMBERS.ZERO, constants.NUMBERS.TWO);
    let facMonth = constants.EMPTY_STRING;
    let facYear = splitDate[constants.NUMBERS.ZERO];
    switch (formatType) {
        case constants.CASE.DATE:
            facMonth = splitDate[constants.NUMBERS.ONE];
            return facYear + constants.SIGNS.GUION + facMonth + constants.SIGNS.GUION + facDay;
        case constants.CASE.TEXT_DATE:
            facMonth = MONTHS_LIST[splitDate[constants.NUMBERS.ONE].substring(constants.NUMBERS.ONE) - constants.NUMBERS.ONE];
            return facDay + constants.DE + facMonth + constants.DE + facYear;
        case constants.CASE.DD_MM_AAAA:
            facMonth = splitDate[constants.NUMBERS.ONE];
            return facDay + constants.SIGNS.SLASH + facMonth + constants.SIGNS.SLASH + facYear;
        default:
            alert(constants.ALERT.MESSAGES.FORMAT_NOT_EXIST)
            break;
    }
}

/**
 * 
 * @param {string[]} arrayExtention -> Array de extenciones validas
 * @param {string} extention -> Extencion a comparar con el array suministrado de extensiones validas
 * @returns {boolean} -> Retorna verdadero si la extension es valida, es decir se encuentra en el array, falso si la extension no se encuentra en el array
 */
const isExtentionsValid = (arrayExtention, extention) => {
    return arrayExtention.some(ext => ext.trim().toLowerCase() === extention.trim().toLowerCase())
}

const validatePermissionsFilial = (dataUser) => {
    if (dataUser && dataUser.filiales) {
        const CODE_FILIAL_PANAMA = constants.NUMBERS_STRING.TWO;
        return dataUser.filiales.find(filial => filial === CODE_FILIAL_PANAMA);
    }

    return constants.NULL;
}

export {
    orderListrequest,
    removePropertyByKey,
    parseCurrencyToVal,
    formatValToCurrency,
    validateInputDecimals,
    getAxiosHeaders,
    avoidNonNumericValues,
    getValueDropdown,
    isInvoiceRequestDisable,
    validateField,
    validateBrandField,
    validateInvoiceField,
    validateClientFields,
    validatePolicyFields,
    isNumberKey,
    isPhoneKey,
    toBase64,
    getStatusName,
    formatDate,
    isExtentionsValid,
    validateLocationData,
    validateUserFields,
    validatePermissionsFilial,
};
